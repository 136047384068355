<template>
  <div>
    <DevToolbar />
    <div style="background-color: bisque;">
      another layout header
    </div>
    <slot />
    <div style="background-color: bisque;">
      another layout footer
    </div>
  </div>
</template>
